package com.mrpowergamerbr.powercmsjs.utils

import org.w3c.dom.Document
import org.w3c.dom.Element
import org.w3c.dom.ParentNode
import org.w3c.dom.asList
import org.w3c.dom.events.Event

inline fun <T> ParentNode.select(query: String): T {
    return this.querySelector(query) as T
}

inline fun <T> ParentNode.selectAll(query: String): List<T> {
    return this.querySelectorAll(query).asList() as List<T>
}

fun Document.onDOMReady(callback: (Event) -> (Unit)) {
    this.addEventListener("DOMContentLoaded", callback, false)
}

fun Element.onClick(callback: (Event) -> (Unit)) {
    this.addEventListener("click", callback)
}