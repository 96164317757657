package com.mrpowergamerbr.powercmsjs

import com.mrpowergamerbr.powercmsjs.utils.Logging
import com.mrpowergamerbr.powercmsjs.utils.select
import com.mrpowergamerbr.powercmsjs.utils.selectAll
import org.w3c.dom.Document
import org.w3c.dom.Element
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.events.Event
import kotlin.browser.document
import kotlin.browser.window
import kotlin.dom.addClass
import kotlin.dom.hasClass
import kotlin.dom.removeClass

class PowerCMSJS : Logging {
    fun start() {
        document.onDOMReady {
            addNavbarOptions()
        }
    }

    fun addNavbarOptions() {
        debug("Adding navbar options!")
        val navbar = document.select<Element?>(".navigation-bar")

        if (navbar != null) {
            debug("Setting up hamburger button events...")

            val hamburgerButton = document.select<Element?>("#hamburger-menu-button")

            hamburgerButton?.onClick {
                debug("Clicked on the hamburger button!")
                if (navbar.hasClass("expanded")) {
                    navbar.removeClass("expanded")
                    document.body!!.style.overflowY = ""
                } else {
                    navbar.addClass("expanded")
                    document.body!!.style.overflowY =
                        "hidden" // Para remover as scrollbars e apenas deixar as scrollbars da navbar
                }
            }

            if (hamburgerButton != null) {
                debug("Setting up resize handler...")

                window.addEventListener("resize", {
                    checkAndFixNavbarOverflownEntries()
                }, true)

                checkAndFixNavbarOverflownEntries()

                debug("Resize handler successfully created!")
            }
        }
    }

    fun checkAndFixNavbarOverflownEntries() {
        val leftSidebar = document.select<HTMLDivElement>(".left-side-entries")
        val hamburgerButton = document.select<HTMLDivElement>("#hamburger-menu-button")

        val isOverflowing = leftSidebar.selectAll<HTMLDivElement>(".entry").any { it.offsetTop != 0 }
        if (isOverflowing) {
            debug("Navbar entries are overflowing, let's unhide the hamburger button!")

            hamburgerButton.style.display = "block"
        } else {
            debug("Navbar entries are not overflowing, let's hide the hamburger button!")

            hamburgerButton.style.display = "none"
        }
    }

        fun Document.onDOMReady(callback: (Event) -> (Unit)) {
            this.addEventListener("DOMContentLoaded", callback, false)
        }

        fun Element.onClick(callback: (Event) -> (Unit)) {
            this.addEventListener("click", callback)
        }
}